import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const LocalAuthorities = () => {
  return (
    <Layout>
      <Seo
        title="Autoridades Locales - BIKESAFE"
        description="Bike Safe colabora con autoridades locales para ofrecer soluciones de aparcabicicletas en centros urbanos, oficinas gubernamentales, estaciones de transporte, y centros educativos, promoviendo el uso de la bicicleta y un entorno más sostenible."
      />
      <Container>
        <Row>
          <Col sm="12">
            <h1 className="mt-5 mb-5 text-center">Autoridades Locales</h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="text-center">
            <p className="flow-text">
              Bike Safe ha trabajado estrechamente con numerosas autoridades
              locales para proporcionar soluciones de aparcabicicletas en
              centros urbanos, centros comerciales, oficinas gubernamentales,
              aparcamientos, y estaciones de tren y autobús. Nuestro objetivo es
              facilitar y fomentar el uso de la bicicleta, contribuyendo a la
              movilidad sostenible en las ciudades.
            </p>
            <p className="flow-text">
              Un aparcamiento para bicicletas accesible y bien ubicado fomenta
              su uso en entornos urbanos, reduce el tráfico y mejora la calidad
              del aire. Promover el ciclismo no solo ayuda a crear un entorno
              más verde, sino que también apoya hábitos saludables en la
              comunidad.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="text-center">
            <StaticImage
              placeholder="blurred"
              src="../images/localauthoritypic.jpg"
              className="shadow mt-5 mb-5 fluid rounded"
            />
            <p>
              <em className="flow-text">
                "Su servicio fue sublime, recomendaría su empresa sin dudar."
                <strong>
                  {" "}
                  Gina Harkell, responsable de ciclismo, London Borough de
                  Waltham Forest
                </strong>
                .
              </em>
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default LocalAuthorities;
